import { ISocialCollectionState, ISocialState } from '@models';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IComment } from '@shared/models/comment-model';
import { SocialPostModel } from '@shared/models/social-post.model';
import * as socialActions from '@store/actions/social';
import * as userActions from '@store/actions/user';

export const NAMESPACE = 'social';

export const adapter = createEntityAdapter<SocialPostModel>({
  selectId: (entity: SocialPostModel) => entity.Id,
});

export function getSocialCollectionId(followedOnly: boolean) {
  return followedOnly ? 'following' : 'all';
}
export function getSocialUserCollectionId(userId: string) {
  return 'user_' + userId;
}
export function getSocialProductCollectionId(productId: string) {
  return 'product_' + productId;
}
export function getSocialPostId(postId: string) {
  return 'post_' + postId;
}

export function getSocialCollectionInitialState(): ISocialCollectionState {
  return adapter.getInitialState({
    isLoading: false,
    showMore: false,
    ids: null,
  });
}

export const initialState = {
  collections: {},
  entities: {},
  isLikeLoading: {},
  isCommentLoading: {},
  isFollowLoading: {},
};

export const SocialReducer = createReducer<ISocialState>(
  initialState,
  on(socialActions.SocialPostsCleanup, (state, { ids }) => {
    const { entities } = adapter.removeMany(ids, {
      entities: state.entities,
      ids: [],
    });
    return {
      ...state,
      entities,
    };
  }),
  on(socialActions.SocialPostsToTop, (state, { collectionId }) => {
    if (!state.collections[collectionId]) return state;

    const collectionState =
      state.collections[collectionId] || getSocialCollectionInitialState();
    return {
      ...state,
      collections: {
        ...state.collections,
        [collectionId]: {
          ...collectionState,
          ids: (collectionState.ids || []).slice(0, 20), // use environment.pageLimit here but breaks unit tests
          showMore: true,
        },
      },
    };
  }),
  on(
    socialActions.SocialPostsRequest,
    (state, { followedOnly }): ISocialState => {
      const collectionId = getSocialCollectionId(followedOnly);
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            isLoading: true,
          },
        },
      };
    }
  ),
  on(
    socialActions.SocialUserPostsRequest,
    (state, { userId }): ISocialState => {
      const collectionId = getSocialUserCollectionId(userId);
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            isLoading: true,
          },
        },
      };
    }
  ),
  on(
    socialActions.SocialProductPostsRequest,
    (state, { productId }): ISocialState => {
      const collectionId = getSocialProductCollectionId(productId);
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            isLoading: true,
          },
        },
      };
    }
  ),
  on(
    socialActions.SocialPostsSuccess,
    (state, { collectionId, take, posts, skip }): ISocialState => {
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      const shouldReset = skip === 0;
      const { entities } = adapter.upsertMany(posts.map(cleanThreadComments), {
        entities: state.entities,
        ids: [],
      });
      return {
        ...state,
        entities,
        collections: {
          ...state.collections,
          [collectionId]: {
            ids: shouldReset
              ? posts.map((p) => p.Id)
              : (collectionState.ids as number[]).concat(
                  posts.map((p) => p.Id)
                ),
            entities: {},
            isLoading: false,
            showMore: posts.length === take,
          },
        },
      };
    }
  ),
  // on(socialActions.SocialPostRequest, (state, { postId }) => {
  //   return {
  //     ...state,
  //     isLoading: {
  //       ...state.isLoading,
  //       [postId]: true,
  //     },
  //   };
  // }),

  on(socialActions.SocialPostRequest, (state, { postId }): ISocialState => {
    const collectionId = getSocialPostId(String(postId));
    const collectionState =
      state.collections[collectionId] || getSocialCollectionInitialState();
    return {
      ...state,
      collections: {
        ...state.collections,
        [collectionId]: {
          ...collectionState,
          isLoading: true,
        },
      },
    };
  }),

  on(
    socialActions.SocialPostSuccess,
    (state, { postId, post }): ISocialState => {
      const collectionId = getSocialPostId(String(postId));
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      const { entities } = adapter.upsertOne(cleanThreadComments(post), {
        entities: state.entities,
        ids: [],
      });
      return {
        ...state,
        entities,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            ids: [postId],
            isLoading: false,
          },
        },
      };
    }
  ),
  on(socialActions.SocialPostLikeToggleRequest, (state, { postId }) => {
    return {
      ...state,
      isLikeLoading: {
        ...state.isLikeLoading,
        [postId]: true,
      },
    };
  }),
  on(
    socialActions.SocialPostLoadCommentsSuccess,
    (state, { postId, comments, parentId }) => {
      if (parentId) return state;
      const { entities } = adapter.updateOne(
        {
          id: postId,
          changes: {
            TopComments: [], // now comments are stored in the comments store
            CommentIds: comments.map((c) => c.Id),
          },
        },
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
      };
    }
  ),
  on(socialActions.SocialPostLikeToggleSuccess, (state, { postId, like }) => {
    const post = state.entities[postId];
    const { entities } = adapter.updateOne(
      {
        id: postId,
        changes: {
          Liked: like.Liked,
          Likes: like.Post.Likes,
          // the nbr of likes updates will come from the socket via SOCIAL_POST_EDIT_SUCCESS
          // Likes: post.Likes + (like ? 1 : -1),
        },
      },
      {
        entities: state.entities,
        ids: [],
      }
    );
    return {
      ...state,
      entities,
      isLikeLoading: {
        ...state.isLikeLoading,
        [postId]: false,
      },
    };
  }),
  on(socialActions.SocialPostLikeToggleError, (state, { postId }) => {
    return {
      ...state,
      isLikeLoading: {
        ...state.isLikeLoading,
        [postId]: false,
      },
    };
  }),
  on(socialActions.SocialPostFollowToggleRequest, (state, { userId }) => {
    return {
      ...state,
      isFollowLoading: {
        ...state.isFollowLoading,
        [userId]: true,
      },
    };
  }),
  on(
    socialActions.SocialPostFollowToggleSuccess,
    (state, { userId, userType, isFollowed }) => {
      const postsToUpdate = Object.keys(state.entities)
        .filter((postId) => {
          const post = state.entities[postId];
          if (userType === 'users') {
            return post.User.UserId === userId;
          } else {
            return post.User.UUID === userId;
          }
        })
        .map((postId) => state.entities[postId]);

      const { entities } = adapter.updateMany(
        postsToUpdate.map((post) => ({
          id: post.Id,
          changes: {
            User: {
              ...post.User,
              Followed: !isFollowed,
            },
          },
        })),
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isFollowLoading: {
          ...state.isFollowLoading,
          [userId]: false,
        },
      };
    }
  ),
  on(socialActions.SocialPostFollowToggleError, (state, { userId }) => {
    return {
      ...state,
      isFollowLoading: {
        ...state.isFollowLoading,
        [userId]: false,
      },
    };
  }),
  on(
    socialActions.SocialPostAddCommentRequest,
    socialActions.SocialPostEditCommentRequest,
    socialActions.SocialPostRemoveCommentRequest,
    (state, { postId }) => {
      return {
        ...state,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: true,
        },
      };
    }
  ),

  on(
    socialActions.SocialPostAddCommentSuccess,
    (state, { postId, comment, isFromSocket }) => {
      const post = state.entities[postId];
      // a post can be undefined if the comment is pushed from the socket
      if (!post) return state;
      if (comment.ParentId) {
        return {
          ...state,
          isCommentLoading: {
            ...state.isCommentLoading,
            [postId]: false,
          },
        };
      }

      // 1st level
      const { entities } = adapter.updateOne(
        {
          id: postId,
          changes: {
            CommentIds: [
              ...new Set((post.CommentIds || []).concat([comment.Id])),
            ],
          },
        },
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: false,
        },
      };
    }
  ),

  // on(
  //   socialActions.SocialPostToggleCommentLikeRequest,
  //   (state, { postId, commentId }) => {
  //     const post = state.entities[postId];
  //     // a post can be undefined if the comment is pushed from the socket
  //     if (!post) return state;
  //     const { entities } = adapter.updateOne(
  //       {
  //         id: postId,
  //         changes: {
  //           TopComments: post.TopComments.map((c) =>
  //             c.Id === commentId ? { ...c, isLoading: true } : c
  //           ),
  //         },
  //       },
  //       {
  //         entities: state.entities,
  //         ids: [],
  //       }
  //     );
  //     return {
  //       ...state,
  //       entities,
  //     };
  //   }
  // ),
  // on(
  //   socialActions.SocialPostToggleCommentLikeSuccess,
  //   (state, { postId, commentId, isLiked, comment }) => {
  //     const post = state.entities[postId];
  //     // a post can be undefined if the comment is pushed from the socket
  //     if (!post) return state;
  //     const { entities } = adapter.updateOne(
  //       {
  //         id: postId,
  //         changes: {
  //           TopComments: post.TopComments.map((c) =>
  //             c.Id === commentId
  //               ? {
  //                   ...c,
  //                   isLoading: false,
  //                   Liked: isLiked,
  //                   Likes: comment.Likes,
  //                 }
  //               : c
  //           ),
  //         },
  //       },
  //       {
  //         entities: state.entities,
  //         ids: [],
  //       }
  //     );
  //     return {
  //       ...state,
  //       entities,
  //     };
  //   }
  // ),
  // on(
  //   socialActions.SocialPostToggleCommentLikeError,
  //   (state, { postId, commentId }) => {
  //     const post = state.entities[postId];
  //     // a post can be undefined if the comment is pushed from the socket
  //     if (!post) return state;
  //     const { entities } = adapter.updateOne(
  //       {
  //         id: postId,
  //         changes: {
  //           TopComments: post.TopComments.map((c) =>
  //             c.Id === commentId ? { ...c, isLoading: false } : c
  //           ),
  //         },
  //       },
  //       {
  //         entities: state.entities,
  //         ids: [],
  //       }
  //     );
  //     return {
  //       ...state,
  //       entities,
  //     };
  //   }
  // ),
  on(
    socialActions.SocialPostEditCommentSuccess,
    (state, { postId, commentId, comment }) => {
      // const post = state.entities[postId];
      // // a post can be undefined if the comment is pushed from the socket
      // if (!post) return state;
      // const { entities } = adapter.updateOne(
      //   {
      //     id: postId,
      //     changes: {
      //       TopComments: post.TopComments.map((c) =>
      //         c.Id === commentId ? comment : c
      //       ),
      //     },
      //   },
      //   {
      //     entities: state.entities,
      //     ids: [],
      //   }
      // );
      return {
        ...state,
        // entities,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: false,
        },
      };
    }
  ),

  on(
    socialActions.SocialPostsCleanupComment,
    (state, { postId, commentId }) => {
      const post = state.entities[postId];
      if (!post) return state;

      const { entities } = adapter.updateOne(
        {
          id: postId,
          changes: {
            CommentIds: post.CommentIds.filter((id) => id !== commentId),
          },
        },
        { entities: state.entities, ids: [] }
      );

      return {
        ...state,
        entities,
      };
    }
  ),
  on(
    socialActions.SocialPostRemoveCommentSuccess,
    (state, { postId, commentId }) => {
      // if postId is not provided, we need to find the post that contains the comment
      // it happens when a comment is removed from the socket
      // all we have is the commentId
      let postIdToRemove = postId;
      if (!postIdToRemove) {
        return state;
        // for (const postId in state.entities) {
        //   const post = state.entities[postId];
        //   if (!post) continue;
        //   if (post.CommentIds.includes(commentId)) {
        //     postIdToRemove = post.Id;
        //     break;
        //   }
        // }
      }
      // const post = state.entities[postIdToRemove];
      // a post can be undefined if the comment is pushed from the socket
      // if (!post) return state;
      // const { entities } = adapter.updateOne(
      //   {
      //     id: postIdToRemove,
      //     changes: {
      //       Comments: post.Comments - 1,
      //       // CommentIds: post.CommentIds.filter((Id) => Id !== commentId),
      //     },
      //   },
      //   {
      //     entities: state.entities,
      //     ids: [],
      //   }
      // );
      return {
        ...state,
        // entities,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postIdToRemove]: false,
        },
      };
    }
  ),
  on(
    socialActions.SocialPostAddCommentError,
    socialActions.SocialPostEditCommentError,
    socialActions.SocialPostRemoveCommentError,
    (state, { postId }) => {
      return {
        ...state,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: false,
        },
      };
    }
  ),
  on(socialActions.SocialPostCreateSuccess, (state, { post }) => {
    const collectionState =
      state.collections['all'] || getSocialCollectionInitialState();

    const userCollectionId = getSocialUserCollectionId(post.User.UserId);
    const userCollectionState = state.collections[userCollectionId];

    // postAlreadyExists=true when the post is pushed from the socket with the same author
    const postAlreadyExists = state.entities[post.Id] !== undefined;
    if (postAlreadyExists) return state;

    // if no feed was loaded yet, we don't need to update the state
    // the user will see the new post when he goes to the feed
    if (!collectionState.ids?.length && !userCollectionState?.ids?.length) {
      return state;
    }

    let newCollectionState = {
      ...state.collections,
    };

    if (Array.isArray(userCollectionState?.ids)) {
      newCollectionState = {
        ...newCollectionState,
        [userCollectionId]: {
          ...userCollectionState,
          ids: [post.Id, ...userCollectionState.ids],
          entities: {},
        },
      };
    }
    if (Array.isArray(collectionState?.ids)) {
      newCollectionState = {
        ...newCollectionState,
        ['all']: {
          ...collectionState,
          ids: [post.Id, ...collectionState.ids],
          entities: {},
        },
      };
    }

    const { entities } = adapter.addOne(post, {
      entities: state.entities,
      ids: [],
    });

    return {
      ...state,
      entities,
      collections: newCollectionState,
    };
  }),
  on(socialActions.SocialPostsCleanCollection, (state, { collectionId }) => {
    return {
      ...state,
      collections: {
        ...state.collections,
        [collectionId]: undefined,
      },
    };
  }),
  on(socialActions.SocialPostEditSuccess, (state, { postId, post }) => {
    if (!state.entities[postId]) return state;

    const { entities } = adapter.updateOne(
      {
        id: postId,
        changes: {
          ...post,
          TopComments: [],
        },
      },
      { entities: state.entities, ids: [] }
    );

    return {
      ...state,
      entities,
    };
  }),
  on(socialActions.SocialPostRemoveSuccess, (state, { postId }) => {
    // a post can be undefined if from the socket
    if (!state.entities[postId]) return state;
    const { entities } = adapter.removeOne(postId, {
      entities: state.entities,
      ids: [],
    });

    return {
      ...state,
      entities,
      collections: Object.keys(state.collections).reduce(
        (acc, collectionId) => ({
          ...acc,
          [collectionId]: {
            ...state.collections[collectionId],
            ids: (state.collections[collectionId]?.ids || []).filter(
              (id) => id !== postId
            ),
          },
        }),
        {}
      ),
    };
  }),
  on(socialActions.SocialBlockUserSuccess, (state, { userId, userType }) => {
    const postIdsToRemove = Object.keys(state.entities).filter((postId) => {
      const post = state.entities[postId];
      if (userType === 'guests') {
        return post.User.UUID === userId;
      } else {
        return post.User.UserId === userId;
      }
    });

    // remove posts from blocked user
    const { entities: postEntities } = adapter.removeMany(postIdsToRemove, {
      entities: state.entities,
      ids: [],
    });

    // remove comments from blocked user
    const { entities } = adapter.map(
      (entity) => {
        return {
          ...entity,
          // TopComments: entity?.TopComments.filter((comment) => {
          //   if (userType === 'guests') {
          //     return comment.User.UUID !== userId;
          //   } else {
          //     return comment.User.UserId !== userId;
          //   }
          // }),
        };
      },
      {
        entities: postEntities,
        ids: Object.keys(state.entities),
      }
    );

    return {
      ...state,
      entities,
      collections: Object.keys(state.collections).reduce(
        (acc, collectionId) => ({
          ...acc,
          [collectionId]: {
            ...state.collections[collectionId],
            ids: (state.collections[collectionId]?.ids || []).filter(
              (id) => !postIdsToRemove.includes(String(id))
            ),
          },
        }),
        {}
      ),
    };
  }),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default SocialReducer;

function cleanThreadComments(post: SocialPostModel) {
  return {
    ...post,
    TopComments: [],
    CommentIds: post.TopComments?.map((comment) => comment.Id),
  };
}
