import { ICommentsState } from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IComment } from '@shared/models/comment-model';
import * as commentsActions from '@store/actions/comments';
import * as socialActions from '@store/actions/social';
import * as userActions from '@store/actions/user';
import {
  getFlatComments,
  getPostFlatComments,
  prepareCommentsForStore,
} from '@utils/social';

export const NAMESPACE = 'comments';

export const adapter: EntityAdapter<IComment> = createEntityAdapter<IComment>({
  selectId: (entity: IComment) => entity.Id,
});

export const initialState: ICommentsState = adapter.getInitialState({
  isThreadLoading: {},
});

export const CommentsReducer = createReducer(
  initialState,
  // on(
  //   commentsActions.CommentsUpdateOne,

  //   (state, { comment }) => ({
  //     ...state,
  //     ...adapter.updateOne(
  //       {
  //         id: comment.Id,
  //         changes: comment,
  //       },
  //       state
  //     ),
  //   })
  // ),
  on(
    socialActions.SocialPostEditCommentSuccess,

    (state, { comment, isFromSocket }) => {
      const { Liked, User, ...rest } = comment;
      return {
        ...state,
        ...adapter.updateOne(
          {
            id: comment.Id,
            changes: isFromSocket ? { ...rest } : comment,
          },
          state
        ),
      };
    }
  ),
  // on(commentsActions.CommentsAddMany, (state, { postId, comments }) => ({
  //   ...state,
  //   posts: {
  //     ...state.posts,
  //     ...posts.reduce((acc, post) => {
  //       return {
  //         ...acc,
  //         [post.Id]: getPostNestedComments(post).map((comment) => comment.Id),
  //       };
  //     }, {}),
  //   },
  //   ...adapter.addMany(getNestedComments(comments), state),
  // })),

  on(
    socialActions.SocialPostLoadCommentsRequest,
    socialActions.SocialPostLoadCommentsError,
    (state, { postId, parentId, type }) => {
      const id = parentId ? `${postId}:${parentId}` : `${postId}`;
      return {
        ...state,
        isThreadLoading: {
          ...state.isThreadLoading,
          [id]: type === socialActions.SocialPostLoadCommentsRequest.type,
        },
      };
    }
  ),
  on(socialActions.SocialPostsCleanup, (state, { ids }) => {
    if (!ids.length) return state;
    const idsToRemove = Object.values(state.entities)
      .filter((comment) => ids.includes(comment.PostId))
      .map((comment) => comment.Id);
    return {
      ...state,
      ...adapter.removeMany(idsToRemove, state),
    };
  }),
  on(
    socialActions.SocialPostLoadCommentsSuccess,
    (state, { postId, parentId, comments }) => {
      const loadingId = parentId ? `${postId}:${parentId}` : `${postId}`;
      const flatComments = prepareCommentsForStore(getFlatComments(comments));

      return {
        ...state,
        ...(parentId
          ? adapter.upsertMany(
              flatComments,
              adapter.updateOne(
                {
                  id: parentId,
                  changes: {
                    ThreadCommentIds: comments.map((comment) => comment.Id),
                  },
                },
                state
              )
            )
          : adapter.upsertMany(flatComments, state)),
        isThreadLoading: {
          ...state.isThreadLoading,
          [loadingId]: false,
        },
      };
    }
  ),
  // on(commentsActions.CommentsDeleteMany, (state, { commentIds }) => ({
  //   ...state,
  //   ...adapter.removeMany(commentIds, state),
  // })),
  on(socialActions.SocialPostsSuccess, (state, { posts }) => {
    const comments = posts.reduce((acc, post) => {
      return [...acc, ...getPostFlatComments(post)];
    }, []);

    return {
      ...state,
      ...adapter.upsertMany(comments, state),
    };
  }),
  // on(socialActions.SocialPostEditSuccess, (state, { post }) => ({
  //   ...state,
  //   ...adapter.upsertMany(getPostFlatComments(post), state),
  // })),
  on(socialActions.SocialPostRemoveSuccess, (state, { postId }) => {
    const idsToRemove = Object.values(state.entities)
      .filter((comment) => postId == comment.PostId)
      .map((comment) => comment.Id);

    return {
      ...state,
      ...adapter.removeMany(idsToRemove, state),
    };
  }),
  on(socialActions.SocialPostRemoveCommentSuccess, (state, { commentId }) => {
    const commentToRemove = state.entities[commentId];

    if (!commentToRemove) return state;

    let adapterState = adapter.removeOne(commentId, state);

    if (!commentToRemove.ParentId) {
      return {
        ...state,
        ...adapterState,
      };
    }

    const parentComment = state.entities[commentToRemove.ParentId];
    if (!parentComment) {
      return {
        ...state,
        ...adapterState,
      };
    }

    return {
      ...state,
      ...adapter.updateOne(
        {
          id: commentToRemove.ParentId,
          changes: {
            ThreadCommentIds: (parentComment.ThreadCommentIds || []).filter(
              (id) => id !== commentId
            ),
          },
        },
        adapterState
      ),
    };
  }),
  on(socialActions.SocialPostAddCommentSuccess, (state, { comment }) => {
    // const firstLevelComments = state.posts[comment.PostId];
    // if (!firstLevelComments) {
    //   // the post is not on the cache
    //   return state;
    // }

    let adapterState = adapter.upsertOne(comment, state);
    if (comment.ParentId) {
      const parentComment = state.entities[comment.ParentId];
      if (!parentComment) {
        // the parent comment is not on the cache
        return state;
      }
      return {
        ...state,
        ...adapter.updateOne(
          {
            id: comment.ParentId,
            changes: {
              ThreadCommentIds: [
                ...new Set(
                  (parentComment.ThreadCommentIds || []).concat([comment.Id])
                ),
              ],
            },
          },
          adapterState
        ),
      };
    } else {
      return {
        ...state,
        ...adapterState,
      };
    }
  }),
  on(
    socialActions.SocialPostToggleCommentLikeRequest,
    (state, { commentId }) => ({
      ...state,
      ...adapter.updateOne(
        {
          id: commentId,
          changes: {
            isLoading: true,
          },
        },
        state
      ),
    })
  ),
  on(
    socialActions.SocialPostToggleCommentLikeSuccess,
    (state, { postId, commentId, isLiked, comment }) => ({
      ...state,
      ...adapter.updateOne(
        {
          id: commentId,
          changes: {
            isLoading: false,
            Liked: isLiked,
            Likes: comment.Likes,
          },
        },
        state
      ),
    })
  ),
  on(
    socialActions.SocialPostToggleCommentLikeError,
    (state, { postId, commentId }) => ({
      ...state,
      ...adapter.updateOne(
        {
          id: commentId,
          changes: {
            isLoading: false,
          },
        },
        state
      ),
    })
  ),
  on(socialActions.SocialBlockUserSuccess, (state, { userId, userType }) => {
    // find the comments and posts ids and filter posts
    const commentsToDelete = Object.keys(state.entities)
      .filter((commentId) => {
        const comment = state.entities[commentId];
        if (userType === 'users') {
          return comment.User.UserId === userId;
        } else {
          return comment.User.UUID === userId;
        }
      })
      .map((postId) => state.entities[postId]);

    const idsToRemove = Object.values(state.entities)
      .filter((comment) => {
        if (userType === 'guests') {
          return comment.User.UUID === userId;
        } else {
          return comment.User.UserId === userId;
        }
      })
      .map((comment) => comment.Id);

    return {
      ...state,
      ...adapter.removeMany(idsToRemove, state),
    };
  }),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default CommentsReducer;
